/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react";

import CookieIcon from "@mui/icons-material/Cookie";
import { sendGTMEvent } from "@next/third-parties/google";
import { SubscriptionPlans } from "@prisma/client";
import { posthog as posthogClient } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { CookieConsent } from "react-cookie-consent";

import { roobert } from "@musicfy/assets/fonts";
import { env } from "@musicfy/env.mjs";

if (typeof window !== "undefined") {
  const baseUrl =
    env.NEXT_PUBLIC_VERCEL_ENV === "development"
      ? "http://localhost:3000"
      : "https://create.musicfy.lol";
  posthogClient.init("phc_b1IjIo9iTOKwDxiCve7abg82mC4SpKQQqz8hDe1qElR", {
    api_host: `${baseUrl}/ingest`,
    autocapture: false,
    disable_session_recording: true,
    disable_scroll_properties: true,
  });
}

const AnalyticsContext = createContext<IAnalyticsContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trackEvent: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  identify: () => {},
});

export function useAnalytics() {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }

  return analyticsContext;
}

interface IAnalyticsContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent: (event: string, properties: Record<string, any>) => void;
  identify: (
    userId: string,
    email: string,
    subscriptionPlan?: SubscriptionPlans | undefined
  ) => void;
}

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const [identifier, setIdentifier] = useState<string | null>(null);
  const [hasConsent, setHasConsent] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionPlans | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackEvent = (event: string, properties: Record<string, any>) => {
    if (typeof window === "undefined") return;

    if (!document.cookie.includes("ga_consent=true")) {
      return;
    }

    posthogClient.capture(event, properties);
    sendGTMEvent({
      event: event,
      userId: identifier,
      email: email,
      subscription: subscriptionPlan ?? SubscriptionPlans.free,
      ...properties,
    });
    if (typeof window !== "undefined" && window.clarity && identifier) {
      window.clarity("event", event);
    }
  };

  const identify = (
    userId: string,
    email: string,
    subscriptionPlan?: SubscriptionPlans | undefined
  ) => {
    setIdentifier(userId);
    setEmail(email);
    setSubscriptionPlan(subscriptionPlan ?? SubscriptionPlans.free);

    sendGTMEvent({
      event: "identify",
      userId: userId,
      email: email,
      subscription: subscriptionPlan ?? SubscriptionPlans.free,
    });
    posthogClient?.identify(
      userId,
      {
        subscription: subscriptionPlan ?? SubscriptionPlans.free,
      },
      {
        email: email,
      }
    );
    if (typeof window !== "undefined" && window.clarity) {
      window.clarity("identify", userId);
    }
  };

  useEffect(() => {
    if (typeof window === "undefined" || !window.dataLayer) return;

    const cookieConsent = document.cookie.includes("ga_consent=true");

    if (cookieConsent) {
      setHasConsent(true);
    }

    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function gtag(...args: any[]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.dataLayer?.push(...args);
    }

    if (!cookieConsent) {
      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    } else {
      gtag("consent", "default", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }

    window.consentAll = () => {
      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });

      window.clarity?.("consent");
    };

    return () => {
      window.consentAll = () => {};
    };
  }, []);

  return (
    <AnalyticsContext.Provider value={{ trackEvent, identify }}>
      <PostHogProvider client={posthogClient}>
        {hasConsent ? null : (
          <CookieConsent
            onAccept={() => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              window.consentAll();
              setHasConsent(true);
            }}
            location="center"
            buttonText="Accept"
            cookieName="ga_consent"
            style={{
              background: "#212023",
              padding: "12px",
              width: "85vw",
              marginLeft: "auto",
              marginRight: "auto",
              left: "0",
              right: "0",
              bottom: "20px",
              borderRadius: "12px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              display: "flex",
              fontFamily: roobert.style.fontFamily,
            }}
            buttonStyle={{
              color: "#030316",
              borderRadius: "8px",
              backgroundColor: "white",
              fontSize: "14px",
              padding: "12px 24px",
              fontWeight: "700",
              marginLeft: "0",
            }}
            buttonWrapperClasses="!w-full xl:!w-fit"
            buttonClasses="!w-full xl:!w-fit"
            expires={150}
          >
            <div className="flex items-start gap-6">
              <CookieIcon className="!text-32" />
              <div className="flex flex-col gap-2 text-16">
                <div className="font-bold">Cookie Consent</div>
                <div className="text-white/70 tracking-wide">
                  This website uses cookies to enhance the user experience and
                  analyze site traffic. By clicking &quot;Accept,&quot; you
                  consent to our use of cookies.
                </div>
              </div>
            </div>
          </CookieConsent>
        )}
        {children}
      </PostHogProvider>
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
