import { useCallback, useMemo } from "react";

import { ModelStatusTypes, type Models } from "@prisma/client";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { v4 as uuid } from "uuid";

import { FEATURE_ROUTES } from "@musicfy/components/Navigation/constants";
import modalAxios, { ModalFunction } from "@musicfy/libs/modalAxios";
import { useModelsContext } from "@musicfy/libs/ModelsProvider";
import { api } from "@musicfy/utils";
import { useStorage } from "@musicfy/utils/hooks";
import { Buckets, type IFileUpload } from "@musicfy/utils/hooks/useStorage";

import { DEFAULT_MODEL, type IModel } from "../IModels";

export const useModels = () => {
  const { models, setModels } = useModelsContext();

  const { data } = useSession();

  const user = data?.user;

  const upsertModelMutation = api.models.upsertModel.useMutation();
  const updateModelMutation = api.models.updateModel.useMutation();
  const deleteModelMutation = api.models.deleteModel.useMutation();

  const createModel = useCallback(
    async (
      model: Partial<Models> & {
        id: string;
        userId: string;
        artist: string;
      }
    ): Promise<[IModel, IModel[]]> => {
      const newModel = await upsertModelMutation.mutateAsync({
        model: {
          ...DEFAULT_MODEL,
          ...model,
        },
      });

      let newModels: IModel[] = [];

      const existingModelIndex = models.findIndex(
        (model) => model.id === newModel.id
      );
      const existingModel = models[existingModelIndex];

      const modifiedModel = {
        ...newModel,
        isFavorited: existingModel?.isFavorited ?? false,
      };

      if (existingModel) {
        newModels = [...models];
        newModels[existingModelIndex] = modifiedModel;
      } else {
        newModels = [modifiedModel, ...models];
      }

      setModels(newModels);

      return [modifiedModel, newModels];
    },
    [upsertModelMutation, models, setModels]
  );

  const updateModel = useCallback(
    (modelId: string) => {
      const modelIndex = models.findIndex((model) => model.id === modelId);

      return (model: Partial<IModel>, upload = false) => {
        setModels((prevModels) => {
          const newModels = [...prevModels];

          const currentModel = newModels[modelIndex];

          if (!currentModel) return newModels;

          newModels[modelIndex] = {
            ...currentModel,
            ...model,
          };

          return newModels;
        });

        if (upload) {
          updateModelMutation.mutateAsync({
            id: modelId,
            ...model,
            updatedAt: new Date(),
          });
        }
      };
    },
    [models, setModels, updateModelMutation]
  );

  const deleteModel = useCallback(
    async (modelId: string) => {
      const modelIndex = models.findIndex((model) => model.id === modelId);
      const model = models[modelIndex];

      if (!model || !user) return;

      setModels((prevModels) => {
        const newModels = [...prevModels];

        newModels.splice(modelIndex, 1);

        return newModels;
      });

      try {
        await deleteModelMutation.mutateAsync({ modelId });
      } catch (err) {
        console.log(err);
      }
      if (model.status !== ModelStatusTypes.completed) {
        return;
      }
      try {
        await modalAxios(ModalFunction.DELETE_MODEL).post("", {
          user_id: user.id,
          model_id: modelId,
        });
      } catch (err) {
        console.log(err);
      }
    },
    [user, deleteModelMutation, models, setModels]
  );

  return {
    models,
    setModels,
    createModel,
    updateModel,
    deleteModel,
  };
};

export const useModelState = <K extends keyof IModel>(
  modelId: string,
  key: K
) => {
  const { models, updateModel } = useModels();

  const model = models.find((m) => m.id === modelId);

  // Infer the type of `value` based on the provided `key`
  const value = model?.[key] ?? null;
  const setValue = useCallback(
    (newValue: typeof value, upload = false) => {
      updateModel(modelId)({ [key]: newValue }, upload);
    },
    [key, modelId, updateModel]
  );

  return { value, setValue };
};
export const useToggleFavoriteModel = () => {
  const { models, updateModel } = useModels();

  const favoriteModelMutation = api.models.favoriteModel.useMutation();
  const unfavoriteModelMutation = api.models.unfavoriteModel.useMutation();

  const toggleFavoriteModel = async (modelId: string): Promise<void> => {
    const modelIndex = models.findIndex((model) => model.id === modelId);

    const model = models[modelIndex];

    if (!model) {
      return;
    }

    const updatedModel = {
      ...models[modelIndex],
      isFavorited: !model.isFavorited,
    };
    updateModel(modelId)(updatedModel);

    if (updatedModel.isFavorited) {
      await favoriteModelMutation.mutateAsync({ modelId });
    } else {
      await unfavoriteModelMutation.mutateAsync({ modelId });
    }
  };

  return toggleFavoriteModel;
};

export const useModelDataset = (modelId: string) => {
  const { data } = useSession();

  const { models, updateModel } = useModels();

  const model = models.find((model) => model.id === modelId);

  const dataset = useMemo(() => {
    const unsortedDataset = model?.dataset ?? [];
    return unsortedDataset.sort((a, b) => {
      if (a.quality && b.quality) {
        if (a.quality === b.quality) {
          return b.path.localeCompare(a.path);
        }
        return b.quality - a.quality;
      }

      if (a.quality) {
        return -1;
      }

      if (b.quality) {
        return 1;
      }

      return b.path.localeCompare(a.path);
    });
  }, [model?.dataset]);

  const { mutateAsync: createModelDatasetRows } =
    api.models.createModelDatasetRows.useMutation();

  const { uploadFiles, isUploadingFiles, uploadProgress, deleteFiles } =
    useStorage();

  const { mutateAsync: deleteModelDatasetRows } =
    api.models.deleteModelDatasetRows.useMutation();

  const uploadDatasetFiles = useCallback(
    (files: IFileUpload[]) => {
      if (!data || !model) return;

      const datasetFiles: IModel["dataset"] = files.map((file) => {
        return {
          id: uuid(),
          path: file.path,
          modelId: model.id,
          userId: data.user.id,
          quality: null,
          createdAt: new Date(),
        };
      });

      const updatedDataset = [...dataset, ...datasetFiles];

      uploadFiles(files);
      updateModel(model.id)({ dataset: updatedDataset });
      createModelDatasetRows({ datasetRows: datasetFiles });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dataset, model, updateModel, uploadFiles]
  );

  const removeDatasetFiles = useCallback(
    async (datasetFileIds: string[], upload = false) => {
      if (!model) {
        return;
      }

      const updatedDataset = dataset.filter(
        (datasetFile) => !datasetFileIds.includes(datasetFile.id)
      );

      updateModel(model.id)({ dataset: updatedDataset });

      if (upload) {
        await deleteModelDatasetRows({ datasetRowIds: datasetFileIds });

        const filesToDelete = datasetFileIds.map((datasetFileId) => {
          const datasetFile = dataset.find(
            (datasetFile) => datasetFile.id === datasetFileId
          );

          return {
            bucket: Buckets.AUDIO_DATASETS,
            path: datasetFile?.path ?? "",
          };
        });
        deleteFiles(filesToDelete);
      }
    },
    [dataset, deleteFiles, deleteModelDatasetRows, model, updateModel]
  );

  return {
    dataset,
    uploadDatasetFiles,
    removeDatasetFiles,
    isUploadingFiles,
    uploadProgress,
  };
};

export const useTrainModel = () => {
  const router = useRouter();

  const { updateModel } = useModels();

  const trainModelMutation = api.models.trainModel.useMutation();

  const trainModel = useCallback(
    async (modelId: string, useHighFidelity = true) => {
      updateModel(modelId)({ status: ModelStatusTypes.training }, true);
      await trainModelMutation.mutateAsync(
        { modelId, useHighFidelity },
        {
          onError: (err) => {
            console.log(err);
            updateModel(modelId)({ status: ModelStatusTypes.failed }, true);
          },
          onSuccess: () => {
            if (router.pathname !== FEATURE_ROUTES.voices) {
              router.push(FEATURE_ROUTES.voices);
            }
          },
        }
      );
    },
    [router, trainModelMutation, updateModel]
  );

  return trainModel;
};
