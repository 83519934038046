import { type IAudioSource } from "@musicfy/libs/GlobalAudioPlayerProvider/types";

const downloadFromAnchor = ({ url, blob }: { url?: string; blob?: Blob }) => {
  const anchor = document.createElement("a");
  const objectUrl = url ?? URL.createObjectURL(blob as Blob);
  anchor.download = blob ? "musicfy-generations.zip" : "";
  anchor.href = objectUrl;
  anchor.click();
  anchor.remove();
  URL.revokeObjectURL(objectUrl);
};

export const downloadFile = async ({
  url,
  audioSources,
}: {
  url?: string;
  audioSources?: IAudioSource[];
}) => {
  if (url) {
    downloadFromAnchor({ url });
  } else if (audioSources) {
    try {
      const response = await fetch("/api/download-zip", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          audioSources,
        }),
      });

      if (!response.ok) {
        const errorData = (await response.json()) as { error: string };
        throw new Error(errorData.error || "Download failed");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "audio-files.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (err) {
      console.error(err);
    }
  }
};
