var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cd086ab9a1bfb7df5d5f0f7f36b057c94e98862c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

import { env } from "@musicfy/env.mjs";

if (env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  Sentry.init({
    dsn: "https://772aaedbea97b75f1f2acabd5e835da1@o4506397095624704.ingest.sentry.io/4506397098770432",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      // eslint-disable-next-line import/namespace
      new Sentry.Replay({
        networkDetailAllowUrls: [
          "https://sovits-app-prod--server-process-inference.modal.run/",
          `https:\/\/musicfy-audio-clips\.16dae716d3a5895243de0cce2bd50595\.r2\.cloudflarestorage\.com\/([a-zA-Z0-9]+)\/([a-zA-Z0-9-]+)\/(.+)
          `,
        ],
        maskAllText: false,
        blockAllMedia: false,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      new CaptureConsole({
        levels: ["error", "log", "warn"],
      }),
    ],
  });
}
